<template>
  <div class="question">
    <div class="question__content" :class="{ answered: object.user_answer }">
      <ContentHeader
        type="Вопрос с проверкой ИИ"
        :text="object.text"
        :description="object.text_description"
        v-if="user.male || !object.female_text"
      />
      <ContentHeader
        type="Вопрос с проверкой ИИ"
        :text="object.female_text"
        :description="object.text_description"
        v-else
      />
      <div v-if="!object.user_answer">
        <CharMessage
          :button="false"
          :text="
            user.male || !object.parent_message.female_text
              ? object.parent_message.text
              : object.parent_message.female_text
          "
          :name="get_name(object.parent_message)"
          :role="get_role(object.parent_message)"
          :img="get_img(object.parent_message).replace(/^http:\/\//i, 'https://')"
          direction="left"
          class="mb-2"
          v-if="object && object.parent_message"
        />
        <textarea rows="4" placeholder="Ваш ответ" v-model="answer"> </textarea>
        <transition name="fade">
          <div class="question__content__error" v-if="loading && !validAnswer">
            Поле ответа не может быть пустым
          </div>
        </transition>
        <div class="question__content__button">
          <FormButton
            :loading="loading"
            text="Отправить"
            :inactive="loading"
            size="medium-large"
            color="primary"
            @click="sendAnswer"
          />
        </div>
      </div>
      <div v-else>
        <CharMessage
          :button="false"
          :text="
            user.male || !object.parent_message.female_text
              ? object.parent_message.text
              : object.parent_message.female_text
          "
          :name="get_name(object.parent_message)"
          :role="get_role(object.parent_message)"
          :img="get_img(object.parent_message).replace(/^http:\/\//i, 'https://')"
          direction="left"
          class="mb-2"
          v-if="object && object.parent_message"
        />
        <CharMessage
          :name="user.last_name + ' ' + user.first_name"
          role="это Вы"
          :img="user.avatar.replace(/^http:\/\//i, 'https://')"
          :button="false"
          direction="right"
          :text="object.user_answer"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FormButton from "@/components/global/form/FormButton";
// import AuthorAnswer from "@/components/global/course/AuthorAnswer";
import CharMessage from "./normalMessage";
// import AfterTaskMessage from "@/components/global/course/AfterTaskMessage";
import ContentHeader from "@/components/global/course/ContentHeader";
// import AnswerCharMessage from "./AnswerCharMessage";
// import FileUploader from "@/components/global/FileUploader";
import { mapGetters } from "vuex";
// import Mark from "./question/Mark";
// import MyComments from "./question/MyComments";
import api from "@/api";
// import postscribe from "postscribe";
export default {
  components: {
    FormButton,
    // FileUploader,
    // AuthorAnswer,
    ContentHeader,
    // AfterTaskMessage,
    // AnswerCharMessage,
    // MyComments,
    // Mark,
    CharMessage,
  },
  props: ["object", "page", "sequence_no", "place_id", "complete"],
  data() {
    return {
      answer: "",
      answer_comment: "",
      process: false,
      type: 1,
      files: [],
      comments: [],
    };
  },
  computed: {
    ...mapGetters({
      simInfo: "simulator/getSimInfo",
      loading: "utility/getCourseTaskLoading",
      user: "user/getMeInfo",
    }),
    validAnswer() {
      return this.answer.length > 0;
    },
    validAnswerComment() {
      return this.answer_comment.length >= 10;
    },
  },
  mounted() {
    // if (
    //   this.object.user_answer &&
    //   this.object.complete &&
    //   this.object.comment_number &&
    //   this.object.comment_number
    // ) {
    //   this.get_answers();
    // }
    // if (
    //   this.object.script_text &&
    //   this.object.script_id &&
    //   this.object.script_id != "" &&
    //   this.object.script_text != ""
    // ) {
    //   let str = this.object.script_text;
    //   postscribe("#" + this.object.script_id, str);
    // }
  },
  methods: {
    get_name(object) {
      if (object.is_current_user) {
        return this.user.last_name + " " + this.user.first_name;
      } else {
        return object.character.first_name + " " + object.character.last_name;
      }
    },
    get_role(object) {
      if (object.is_current_user) {
        return "Это вы";
      } else {
        return object.character.social_role;
      }
    },
    get_img(object) {
      if (object.is_current_user) {
        return this.user.avatar;
      } else {
        return "//newapi.mysimulator.ru" + object.character.avatar;
      }
    },
    get_answers() {
      api.get(`notifications.json?place=${this.object.id}`).then((response) => {
        this.comments = response.data;
      });
    },
    deleteFile(file, index) {
      this.files.splice(index, 1);
    },
    addFile(file) {
      this.files.push(file);
    },
    async sendAnswer() {
      this.$store.commit("utility/SET_COURSE_TASK_LOADING", true);
      if (this.validAnswer) {
        this.$store.commit("utility/SET_COURSE_TASK_LOADING", true);
        await this.$store.dispatch("course/SEND_TEXT_QUESTION", {
          page_id: this.page,
          answer: this.answer,
          place: this.place_id,
        });

        this.$store.commit("utility/SET_COURSE_TASK_LOADING", false);

        if (this.object.need_notifications) {
          this.$store.dispatch("simulator/SEND_NOTIFICATION", {
            type: "open",
            id: this.object.id,
            text: this.object.text,
            answer: this.answer,
          });
        }
      } else {
        setTimeout(() => {
          this.process = false;
          this.$store.commit("utility/SET_COURSE_TASK_LOADING", false);
        }, 1500);
      }
    },
    async sendCommentAnswer(id) {
      this.$store.commit("utility/SET_COURSE_TASK_LOADING", true);
      this.process = true;
      if (this.validAnswerComment) {
        await this.$store.dispatch("course/SEND_COMMENT", {
          page_id: this.page,
          place: this.place_id,
          comment: this.answer_comment,
          answer_id: id,
        });
        this.process = false;
        this.answer_comment = "";
      } else {
        setTimeout(() => {
          this.process = false;
          this.$store.commit("utility/SET_COURSE_TASK_LOADING", false);
        }, 1500);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/vars.scss";

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s ease;
}
textarea {
  resize: none;
  width: 100%;
  border: 1px solid $third_color;
  box-shadow: 0px 4px 8px rgba(44, 39, 56, 0.04);
  border-radius: 6px;
  padding: 16px;
}
.question {
  // margin-bottom: 40px;
  &__content {
    padding: 25px 30px 30px 30px;
    border: solid 1px $third_color;
    border-radius: 10px;
    margin-bottom: 10px;

    &__error {
      color: $color_wrong;
      font-size: $smallestTextSize;
    }
    &__button {
      margin-top: 30px;
    }
    &.answered {
      background-color: $second_background_color;
      border: none;
      .question__title {
        &__type {
          color: $second_font_color;
        }
      }
    }
  }
  // &.answered{

  // }
  &__comment {
    width: 100%;
    display: table;
    cursor: pointer;
    margin-bottom: 1rem;
    &-type {
      transition: color 0.2s ease, border-bottom 0.2s ease;
      font-size: 1rem;
      float: left;
      margin-right: 2rem;
      color: $second_font_color;
      border-bottom: 1px dashed $second_font_color;
      margin-bottom: 1rem;
    }
    &-type:last-child {
      margin-right: 0;
    }
  }
  &__answer-comment {
    &__title {
      margin-bottom: 0.55rem;
    }
    &__answer {
      margin-bottom: 1.25rem;
    }
    &__button {
      margin-top: 0.55rem;
    }
  }
  .comments_left {
    font-size: 14px;
  }
}
</style>
